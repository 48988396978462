<template>
  <app-modal
    :title="$t('modal.media_url_header')"
    @close="close"
  >
    <!-- Body -->
    <template slot="body">
      <div class="row">
        <div class="col-8">
          <app-input
            v-model.trim="url"
            :label="mediaUrlModalLabelDesc"
            :maxlength="999"
            :disabled="instagramCdnPanel"
            id="search_url"
            @input="inputHandler"
          />
          <app-input
            v-model.trim="imageName"
            :label="$t('modal.image_name')"
            :maxlength="999"
            id="image_name"
          />
          <p class="alert alert-warning" v-if="invalidUrl">
            <i class="fa fa-exclamation-triangle"></i>
            <span v-if="typeOfModal === 'url'">{{ $t('modal.image_url_error_message') }}</span>
            <span v-if="typeOfModal === 'embed'">{{ $t('modal.image_url_error_message_embed') }}</span>
          </p>
        </div>
        <div class="col-4">
          <button
            @click="extractFromUrl"
            type="button"
            class="btn btn-info btn-extract-from-url"
            data-test="media_btn_extractFromUrl"
            :disabled="instagramCdnPanel || invalidUrl"
          >
            <i class="fa fa-check"></i> {{ $t('modal.select') }}
          </button>
        </div>
      </div>
      <div v-if="isInstagramPostUrlValid(url)" class="row m-b-10 p-l-20">
        {{ $t('modal.instagram.previewInfo') }}
      </div>
      <div class="row">
        <div class="col-9 instagram-cdn-panel" v-if="instagramCdnPanel">
          <div class="row">
            <div class="col-11">
              <div v-html="$t('modal.instagram.text1')" />
              <div v-html="$t('modal.instagram.text2')" />
              <div v-html="$t('modal.instagram.text3')" />
              <div class="step-text-indent">
                {{ changedUrl }}
                <button
                  class="btn-copy"
                  type="button"
                  @click="copyToClipboard(changedUrl, $event)"
                  data-test="media_url_modal_copy_to_clipboard"
                >
                <span class="btn-copy__content">
                  <IconCopy class="btn-copy__icon" />
                  {{ $t('buttons.copy_url') }}
                </span>
                </button>
              </div>
              <div v-html="$t('modal.instagram.text4')" />
              <div v-html="$t('modal.instagram.text5')" />
              <div v-html="$t('modal.instagram.text6')" class="step-text-indent" />
            </div>
            <div class="col-1">
              <button
                type="button"
                class="close"
                @click="closeInstagramCdnPanel"
              >
                ×
              </button>
            </div>
          </div>
          <div class="row">
            <div class="col-10">
              <app-input
                v-model.trim="instagramCdnUrl"
                :maxlength="9999"
                :placeholder="$t('modal.instagram.cdnUrlPlaceholder')"
                id="instagram_cdn_url"
              />
              <p class="alert alert-warning" v-if="error">
                <i class="fa fa-exclamation-triangle"></i>
                {{ $t('modal.instagram.cdnUrlError') }}
              </p>
            </div>
            <div class="col-2">
              <button
                @click="extractFromUrl"
                type="button"
                class="btn btn-info btn-extract-from-url"
                data-test="media_btn_extractFromUrlCdn"
                :disabled="!instagramCdnUrl || !urlIsSocial(instagramCdnUrl)"
              >
                <i class="fa fa-check"></i> {{ $t('modal.insert') }}
              </button>
            </div>
          </div>
        </div>
        <div class="col-2 instagram-preview">
          <div v-if="isInstagramPostUrlValid(url)" v-html="instagramPreview" />
          <iframe
            v-if="false && isInstagramPostUrlValid(url)"
            :src="`${url}/embed`"
            width="240px"
            height="500px"
            frameborder="0"
            scrolling="no"
          />
        </div>
      </div>
      <app-preloader v-if="callingAPI"></app-preloader>
    </template>
  </app-modal>
</template>

<script>
import { mapState } from 'vuex'
import Input from '@/components/form/inputs/Input'
import Preloader from '@/components/preloader/Preloader'
import DamApi from '@/api/dam'
import Modal from '@/components/shared/Modal'
import { SOURCE_EAGLE_URL } from '@/model/ValueObject/DamUploadSources'
import IconCopy from '@/assets/img/svg/copy.svg?inline'
import ClipboardService from '@/services/ClipboardService'
import NotifyService from '@/services/NotifyService'
import MediaService from '@/services/media/MediaService'
import { debounce } from 'lodash'

export default {
  name: 'MediaUrlModal',
  props: {
    typeOfModal: {
      type: String, // url or embed
      required: false,
      default: 'url'
    },
    mediaUrlModalLabelDesc: {
      type: String,
      required: false,
      default: function () {
        return this.$t('modal.image_url')
      }
    }
  },
  data () {
    return {
      url: '',
      imageName: '',
      changedUrl: '',
      instagramCdnUrl: '',
      instagramPreview: '',
      instagramCdnPanel: false,
      error: false,
      invalidUrl: false
    }
  },
  computed: {
    ...mapState([
      'callingAPI'
    ]),
    imageSettings () {
      return this.$store.getters['media/imageSettings']
    }
  },
  components: {
    appModal: Modal,
    appInput: Input,
    appPreloader: Preloader,
    IconCopy
  },
  methods: {
    close () {
      this.$emit('close')
    },
    showInstagramCdnPanel () {
      this.instagramCdnPanel = true
    },
    closeInstagramCdnPanel () {
      this.instagramCdnUrl = ''
      this.instagramCdnPanel = false
    },
    copyToClipboard (data, event) {
      ClipboardService.copy(data, event)
      NotifyService.setSuccessMessage(this.$t('notify.url_was_copied'))
    },
    async extractFromUrl () {
      this.error = false
      this.invalidUrl = false
      this.$store.commit('TOGGLE_LOADING')
      if (this.typeOfModal === 'embed' && !this.urlIsSocial(this.url)) {
        this.invalidUrl = true
        this.$store.commit('TOGGLE_LOADING')
        return
      }
      let url = this.url
      if (this.typeOfModal === 'embed') {
        url = this.instagramCdnUrl || this.changedUrl
      }
      const bodyData = {
        url,
        uploadSource: SOURCE_EAGLE_URL,
        imageName: this.imageName,
        categoryCode: undefined,
        siteLocks: [],
        unlockAfterPublish: true,
        maxCropWidth: this.imageSettings.maxCropWidth,
        maxCropHeight: this.imageSettings.maxCropHeight
      }

      try {
        const files = []
        const response = await DamApi().post('/image/from-url', bodyData)
        const { imageAsset, uploadedImageAsset, wasCreated } = response.data
        const file = MediaService.convertToUploadedFile({
          imageAsset,
          uploadedImageAsset,
          wasCreated,
          fileBase64: await MediaService.urlToBase64(this.url)
        })
        files.push(file)
        if (this.urlIsSocial(this.url)) {
          this.$emit('get-raw-url', this.url)
        }
        this.$emit('upload-finished', files)
      } catch (error) {
        console.error(error)
        if (this.url.includes('instagram.com')) {
          if (!this.instagramCdnPanel) {
            this.showInstagramCdnPanel()
          } else {
            this.error = true
          }
        } else {
          this.invalidUrl = true
        }
      } finally {
        this.$store.commit('TOGGLE_LOADING')
      }
    },
    urlIsSocial (data) {
      return ['https://instagram', 'https://www.instagram', 'youtube.com']
        .some(url => data.includes(url))
    },
    refreshInstagramPreview () {
      this.instagramPreview = `
        <blockquote
          class="instagram-media"
          data-instgrm-captioned
          data-instgrm-permalink="${this.url}"
          data-instgrm-version="14"
        />
      `
      this.$nextTick(() => {
        window.instgrm.Embeds.process()
      })
    },
    refreshInstagramPreviewDebounce: debounce(function () {
      this.refreshInstagramPreview()
    }, 500),
    isInstagramPostUrlValid (url) {
      return /^https:\/\/(www.)?instagram.com\/p\/.+/.test(url)
    },
    inputHandler (data) {
      this.imageName = this.url.split('/').pop()
      if (this.typeOfModal === 'embed') {
        this.url = data
        this.instagramPreview = ''
        this.changedUrl = ''
        this.invalidUrl = !this.urlIsSocial(this.url)
        if (data.substr(-1) === '/') {
          data = data.slice(0, -1)
        }
        if (this.urlIsSocial(data)) {
          if (data.substr(-1) === '/') {
            data = data.slice(0, -1)
          }
          if (data.includes('instagram.com')) {
            this.invalidUrl = !this.isInstagramPostUrlValid(this.url)
            this.changedUrl = `${data}/media/?size=l`
            this.refreshInstagramPreviewDebounce()
          }
          if (data.includes('youtube.com')) {
            const regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/ /* eslint-disable-line */
            const match = data.match(regExp)
            if (match && match[2].length === 11) {
              this.changedUrl = `https://img.youtube.com/vi/${match[2]}/maxresdefault.jpg`
            }
          }
        }
      }
    }
  },
  mounted () {
    this.$el.querySelector('#search_url').focus()
  }
}
</script>

<style lang="scss">
.instagram-preview {
  .instagram-media {
    width: 240px !important;
  }
}
</style>
<style scoped lang="scss">
.btn-extract-from-url {
  @include margin(26px _ _);
}
.btn-copy {
  border: 0;
  background: none;
  margin-top: rem(-5px);
  &__content {
    border-radius: rem(4px);
    border: 1px solid #D1DBE4;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: rem(0px) rem(8px);
    &:hover {
      border: 1px solid #6599FE;
      color: #6599FE;
      .btn-copy__icon {
        fill: #6599FE;
      }
    }
  }
  &__icon {
    width: rem(14px);
    height: auto;
    fill: #9DAAC4;
    margin-right: rem(6px);
  }
}
.instagram-cdn-panel {
  border: 1px solid #D1DBE4;
  border-radius: rem(6px);
  margin: rem(16px);
  padding: rem(16px);
  .step-text-indent {
    padding-left: 57px;
  }
}
</style>
