const copy = (value, event) => {
  const el = document.createElement('input')
  el.value = value
  document.body.appendChild(el)
  el.select()
  document.execCommand('copy')
  document.body.removeChild(el)
  event.stopPropagation()
}

export default {
  copy
}
